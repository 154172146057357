.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
}

li {
    margin-bottom: 4px;
}

.skeleton {
    width: 80%;
    height: 1em;
    background-color: lightgrey;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

button {
    width: 49%;
    padding: 8px 24px;
    color: white;
    background-color: black;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: 250ms ease-out;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover {
    background-color: rgba(0, 0, 0, 0.75);
}

button:active {
    background-color: rgba(0, 0, 0, 0.6);
}

button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: black;
}

@media screen and (max-width: 768px) {
    .container {
        width: 90%;
        margin: 0 5%;
    }
}
